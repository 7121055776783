import React from 'react';
import * as styles from "./orte.module.scss";

import Layout from "../page-components/layout/layout"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const OrtePage = ( { data } ) => {

  const all = data.allWpStadt.nodes.sort(
    (a, b) => a.title.localeCompare(b.title)
  );

  const firstChars = all.map(
                          d => {
                            if (d.title.startsWith("St.")) {
                              return "St.";
                            }

                            if (d.title.startsWith("Ü")) {
                              return "U";
                            }

                            return d.title[0].toUpperCase()
                          }
                        )
                        .filter( (f, idx, arr) => arr.indexOf(f) === idx);


  return <Layout>


    {data.wp.themeGeneralSettings.acf_settings.teaserort?.localFile &&
      <GatsbyImage alt={"Teaser Image Orte"}
                   className={styles.teaser}
                   image={data.wp.themeGeneralSettings.acf_settings.teaserort.localFile.childImageSharp.gatsbyImageData} />
    }


    <div className="py-4 bg-primary-dark text-white text-center">
      <div className="container">
        <div className="row">
            <div className="col-12">

              <h1>Städte und Gemeinden</h1>

          </div>
        </div>
      </div>
    </div>


    <div className={"container " + styles.sections}>
      <div className="row pb-5">

          {
            firstChars.map(fc => {
              return <div className={"col-lg-4"}>

                <div className={"mt-5 text-center mb-2 text-white py-2 bg-primary-dark"}>
                  <h3>{ fc }</h3>
                </div>

                {
                  all
                    .filter(d => {
                      if (d.title.startsWith("St.")) {
                        return fc === "St.";
                      } else if (d.title.startsWith("Ü")) {
                        return fc === "U";
                      } else {
                        return d.title.startsWith(fc)
                      }
                    })
                    .map(
                      a => {
                        return <div className={styles.ort} >
                          <Link className={styles.ort} to={"/ort/" + a.slug}>{a.title}</Link>
                        </div>
                      }
                    )
                }


              </div>
            })
          }

      </div>
    </div>

  </Layout>

};

export default OrtePage;


export const query = graphql`
  query OrteListQuery {
  
    wp {
      themeGeneralSettings {
        acf_settings {
          teaserort {
            localFile {
              childImageSharp {
                gatsbyImageData(layout:FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  
    allWpStadt {
      nodes {
        title
        slug
        acf_stadt {
          place {
            longitude
            latitude
          }
        }
      }
    }
  }
`;
